var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("setup-table", {
    attrs: {
      title: `Additional Invoice Cost`,
      items: _vm.items,
      headers: _vm.headers,
      loading: _vm.loading,
      clearItem: !_vm.showForm || !_vm.editedItem.id,
    },
    on: {
      createItem: function ($event) {
        return _vm.createItem()
      },
      editItem: _vm.editItem,
      deleteItems: _vm.deleteItems,
    },
    scopedSlots: _vm._u(
      [
        _vm.showForm
          ? {
              key: "itemForm",
              fn: function () {
                return [
                  _c("additional-invoice-costs-form", {
                    ref: "additionalInvoiceCostsForm",
                    attrs: { value: _vm.editedItem },
                    on: { submit: _vm.saveItem, closeForm: _vm.closeForm },
                  }),
                ]
              },
              proxy: true,
            }
          : null,
        {
          key: `item.amount`,
          fn: function ({ item }) {
            return [
              _c("p", { staticClass: "mb-0" }, [
                _vm._v("$" + _vm._s(item.amount)),
              ]),
            ]
          },
        },
        {
          key: `item.displaySection`,
          fn: function ({ item }) {
            return [
              _c("p", { staticClass: "mb-0" }, [
                _vm._v(_vm._s(item.displaySection ? "Vehicle" : "Driver")),
              ]),
            ]
          },
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }